import React, { useState, useEffect } from 'react';
import {auth} from './firebase';
import MessageList from './MessageList';
import './styling/chat_app.css';
import chattermunkNoLogo from './images/chattermunkNoLogo.png'
import chattermunkLogo from './images/chattermunkLogo.png'
import {useAuth} from "./useAuth";
import {usePersonality} from "./usePersonality";
import {useMessages} from "./useMessages";

function ChatApp() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const {
        user,
        authError,
        signInWithFirebase,
        logout,
        registerWithFirebase
    } = useAuth();

    const { PERSONALITIES } = usePersonality();
    const [assistantPersonality, setAssistantPersonality] = useState(PERSONALITIES.default);

    const {
        handleSend,
        setGreetingSent,
        loadingGreeting,
        messages,
        setMessages,
        message,
        setMessage
    } = useMessages(assistantPersonality);

    const handleChangePersonality = (personalityKey) => {
        setAssistantPersonality(PERSONALITIES[personalityKey]);
        document.documentElement.setAttribute('data-personality', personalityKey);
        setIsMenuOpen(false); // Close the menu
        setMessages([]);  // Clear current messages
        setGreetingSent(false);  // Trigger a new greeting to be sent
    };

    const handleLogin = () => {
        signInWithFirebase(email, password);
    };

    const handleRegistration = () => {
        registerWithFirebase(email, password);
    };

    const handleLogout = () => {
        auth.signOut();
        setIsMenuOpen(false); // Close the menu
    };

    useEffect(() => {
        document.title = "chitterAI";
    }, []);

    const renderSignInContainer = () => (
        <div className={`signin-container ${isLoggingIn ? 'fade-out' : ''}`}>
            <img src={chattermunkNoLogo} className="signin-logo" alt="chitterAI Logo" />
            <h1 className="signin-title">chitterAI</h1>
            <button onClick={handleLogin}>Sign In</button>
            <input type="email" placeholder="Email" onChange={e => setEmail(e.target.value)} />
            <input type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
            <button onClick={handleRegistration}>Register</button>
            {user ? <p>Welcome, {user.email}</p> : <p>Please log in</p>}
            {user && <button onClick={handleLogout}>Log Out</button>}
            {authError && <p>{authError.message}</p>}
        </div>
    );

    const renderChatUI = () => (
        <>
            <div className="top-menu">
                <img src={chattermunkLogo} alt="chitterAI Logo" className="menu-logo" />
                <div>
                    <select
                        className="personality-select"
                        value={assistantPersonality.name}
                        onChange={(e) => handleChangePersonality(e.target.value)}
                    >
                        {Object.entries(PERSONALITIES).map(([key, personality]) => (
                            <option key={key} value={key}>
                                {personality.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="menu-icon" onClick={(e) => {
                    e.stopPropagation();
                    setIsMenuOpen(prevState => !prevState);
                }}>
                    ☰
                </div>
                {isMenuOpen &&
                    <div className="menu-options">
                        <a href="#" onClick={handleLogout}>Logout</a>
                    </div>
                }
            </div>
            <MessageList
                messages={messages}
                loadingGreeting={loadingGreeting}
                assistantPersonality={assistantPersonality}
            />
            <div className="input-layout">
                <input
                    type="text"
                    placeholder="Type your message here..."
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    onKeyPress={e => e.key === 'Enter' && handleSend()}
                />
                <button onClick={handleSend}>Send</button>
            </div>
        </>
    );

    return (
        <div className="main-container">
            {user ? renderChatUI() : renderSignInContainer()}
        </div>
    );
}

export default ChatApp;