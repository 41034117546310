// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDty9-FHGi5u1k86F7R4IfWEPay3Abz5Xw",
    authDomain: "chitter-ai-web-host.firebaseapp.com",
    projectId: "chitter-ai-web-host",
    storageBucket: "chitter-ai-web-host.appspot.com",
    messagingSenderId: "492829678038",
    appId: "1:492829678038:web:d3e231f7f61f675838ac77",
    measurementId: "G-W1LDZCNNR9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const db = getFirestore(app);

// Export services for use in other parts of your app
export { auth, db };