import React from 'react';
import { ScaleLoader } from 'react-spinners';
import senderAvatar from './images/avatars/User.png'

const MessageItem = ({ message, loadingGreeting, assistantPersonality }) => {
    const { content, isUser, type } = message;

    if (type === 'LOADING' || loadingGreeting === true) {
        return (
            <div className="loading-message">
                <ScaleLoader color="#00FFFF" size={20} />
            </div>
        );
    }

    if (type === "IMAGE") {
        return (
            <div className={isUser ? "item-user-message" : "image-message-layout"}>
                <img src={`data:image/png;base64,${content}`} alt="Message content" />
            </div>
        );
    }

    return (
        <div className={isUser ? "item-user-message" : "item-assistant-message"}>
            <img
                src={isUser ? senderAvatar : assistantPersonality.image}
                alt={isUser ? "User Avatar" : "Assistant Avatar"}
                className="avatar"
            />
            <div className={isUser ? "user-message-background text-message" : "assistant-message-background text-message"}>
                {content}
            </div>
        </div>
    );
}

export default MessageItem;
