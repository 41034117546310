import {
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithRedirect,
    signOut
} from "firebase/auth";
import {auth, db} from "./firebase";
import {doc, getDoc, setDoc} from "firebase/firestore";
import { useState, useEffect } from 'react';

export const useAuth = () => {
    const [user, setUser] = useState(null);
    const [authError, setAuthError] = useState(null);

    useEffect(() => {
        // Optional: You might want to check if a user is already logged in
        // when the app starts. This is usually done by listening to auth state changes.
        // This is just a placeholder, you'll need to implement this based on your auth provider.
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            if (currentUser) {
                setUser(currentUser);
            } else {
                setUser(null);
            }
        });

        // Cleanup the listener on unmount
        return () => unsubscribe();
    }, []); // Empty dependency array ensures this runs once when the hook is first used.

    const signInWithFirebase = async (email, password) => {
        try {
            const storeUser = await signInWithEmailAndPassword(auth, email, password);
            if (storeUser != null)
            {
                const userRef = doc(db, 'Users', storeUser.user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    console.log("uid: " + storeUser.user.uid)
                    setUser({
                        uid: storeUser.user.uid,
                        displayName: userDoc.data().displayName || storeUser.user.displayName,
                        email: storeUser.user.email
                    });
                } else {
                    console.warn("User document doesn't exist in Firestore");
                }
            }
        } catch (error) {
            console.error("Error during sign-in:", error.message); // Log the error message
            setAuthError(error.message);
        }
    };

    const logout = () => {
        signOut(auth).catch(error => {
            console.error("Error logging out:", error);
        });
    };

    const registerWithFirebase = async (email, password) => {
        try {
            const storeUser = await createUserWithEmailAndPassword(auth, email, password);

            if (storeUser != null) {
                // Save user details to Firestore
                await setDoc(doc(db, 'Users', storeUser.user.uid), {
                    displayName: storeUser.user.displayName,
                    email: storeUser.user.email
                    // Add any other fields you want to save
                });

                setUser(storeUser.user);
            }
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                setAuthError('This email is already registered. Try signing in.');
            } else {
                setAuthError(error.message);
            }
        }
    };

    const googleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithRedirect(auth, provider);
            // This gives you a Google Access Token. You can use it to access the Google API.
            const token = result.credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            setUser(user);  // Save the user object or any info you need
        } catch (error) {
            console.error(error.message);
        }
    };

    return { user, authError, signInWithFirebase, logout, registerWithFirebase };
};