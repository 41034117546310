import React, { useRef, useEffect } from 'react';
import MessageItem from './MessageItem';

const MessageList = ({ messages, loadingGreeting, assistantPersonality }) => {
    const messagesEndRef = useRef(null);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    // Guard clause to ensure messages is an array
    if (!Array.isArray(messages)) {
        console.warn("Invalid messages prop:", messages);
        return null;
    }

    return (
        <div className="message-list">
            {messages.map((message) => (
                <MessageItem
                    key={message.id || Math.random()}
                    message={message}
                    loadingGreeting={loadingGreeting}
                    assistantPersonality={assistantPersonality}  // Pass the assistantPersonality down
                />
            ))}
            <div ref={messagesEndRef} />
        </div>
    );
}

export default MessageList;
