import {addDoc, arrayUnion, collection, doc, getDocs, query, updateDoc, where} from "firebase/firestore";
import {db} from "./firebase";
import axios from "axios";
import {useEffect, useState} from "react";
import {useAuth} from "./useAuth";
import {usePersonality} from "./usePersonality";

export const useMessages = (assistantPersonality) => {
    const [isSending, setIsSending] = useState(false);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [greetingSent, setGreetingSent] = useState(false);
    const [loadingGreeting, setLoadingGreeting] = useState(true);
    const { user } = useAuth();

    const handleSend = async () => {
        if (isSending || !message) return;

        const uniqueId = Date.now();
        const userMessage = { id: uniqueId, content: message, isUser: true, type: 'TEXT' };
        const loadingMessage = { content: null, isUser: false, type: 'LOADING' };
        setMessages(prevMessages => [...prevMessages, userMessage, loadingMessage]);

        // Check if this user already has a conversation with this assistant personality
        const conversationsCollection = collection(db, 'Conversations');
        const q = query(conversationsCollection, where('userId', '==', user.uid), where('assistantId', '==', assistantPersonality.name));
        const snapshot = await getDocs(q);

        if (snapshot.empty) {
            // If no conversation exists, create one
            const newConvo = {
                userId: user.uid,
                assistantId: assistantPersonality.name,
                messages: [userMessage]
            };
            await addDoc(conversationsCollection, newConvo);
        } else {
            // If a conversation already exists, just append the new message
            const docRef = doc(db, 'Conversations', snapshot.docs[0].id);
            await updateDoc(docRef, {
                messages: arrayUnion(userMessage)  // Ensure you've imported arrayUnion
            });
        }

        try {
            const response = await axios.post('https://chitterai-71468a4c01ab.herokuapp.com/command', {
                user_input: message,
                message_id: uniqueId,
                personality: assistantPersonality.description
            });
            const jsonResponse = response.data;

            // Handle UI Updates first
            setMessages(prevMessages => {
                let updatedMessages = [...prevMessages];
                updatedMessages.pop(); // Remove loading

                if (jsonResponse.response_text) {
                    const assistantResponse = {
                        id: Date.now(),
                        content: jsonResponse.response_text,
                        isUser: false,
                        type: 'TEXT'
                    };
                    updatedMessages.push(assistantResponse);
                }

                if (jsonResponse.response_image) {
                    const assistantImageResponse = {
                        id: Date.now(),
                        content: jsonResponse.response_image,
                        isUser: false,
                        type: 'IMAGE'
                    };
                    updatedMessages.push(assistantImageResponse);
                }

                return updatedMessages;
            });

            // Then handle Firestore updates
            const docRef = doc(db, 'Conversations', snapshot.docs[0].id);

            if (jsonResponse.response_text) {
                const assistantResponse = {
                    id: Date.now(),
                    content: jsonResponse.response_text,
                    isUser: false,
                    type: 'TEXT'
                };
                await updateDoc(docRef, {
                    messages: arrayUnion(assistantResponse)
                });
            }

            if (jsonResponse.response_image) {
                const assistantImageResponse = {
                    id: Date.now(),
                    content: jsonResponse.response_image,
                    isUser: false,
                    type: 'IMAGE'
                };
                await updateDoc(docRef, {
                    messages: arrayUnion(assistantImageResponse)
                });
            }

        } catch (error) {
            console.error("Error sending message:", error);
        } finally {
            setMessage("");
            setIsSending(false);
        }
    };

    useEffect(() => {
        const sendGreetingMessage = async () => {
            const initialLoadingMessage = { content: null, isUser: false, type: 'LOADING' };
            setMessages([initialLoadingMessage]);
            try {
                const uniqueId = Date.now();
                const response = await axios.post('https://chitterai-71468a4c01ab.herokuapp.com/command',
                    {
                        user_input: 'Could you please welcome the user to chitterAI and let them know who you are?',
                        message_id: uniqueId,
                        personality: assistantPersonality.description
                    });
                const jsonResponse = response.data;
                if (jsonResponse.response_text) {
                    setMessages(prevMessages => {
                        let updatedMessages = [...prevMessages];
                        updatedMessages.pop(); // Remove the loading message
                        updatedMessages.push({ id: uniqueId, content: jsonResponse.response_text, isUser: false, type: 'TEXT' });
                        return updatedMessages;
                    });

                }
            } catch (error) {
                console.error("Error loading greeting:", error);
            } finally {
                setLoadingGreeting(false);
            }
        };

        if (!greetingSent) {
            sendGreetingMessage();
            setGreetingSent(true);
        }
    }, [assistantPersonality, greetingSent]);

    return { handleSend, setGreetingSent, loadingGreeting, messages, setMessages, message, setMessage };
};